// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

document.addEventListener("DOMContentLoaded", (event) => {
    var header = document.getElementById('header')
    if (header && 'data-lq-dynamic' in header.attributes) {
        header.classList.remove('bg-white')
        header.classList.add('bg-transparent')
        window.addEventListener("scroll", function() {
            if (window.scrollY <= 0) {
                if (header.classList.contains('bg-white')) {
                    header.classList.add('bg-transparent')
                    header.classList.remove('bg-white')
                }
            } else {
                if (header.classList.contains('bg-transparent')) {
                    header.classList.add('bg-white')
                    header.classList.remove('bg-transparent')
                }
            }
        });
    }
});
