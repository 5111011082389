// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

var html = document.getElementsByTagName('html')[0]
html.classList.add('aos-enable')

document.addEventListener("DOMContentLoaded", function(event) {
    AOS.init({
        duration: 700,
        easing: 'ease-out',
        offset: 20,
        once: true
    });
});
